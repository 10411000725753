import { of, from } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { getUsers as slice } from 'modals/account/followers/slices'
import { identity } from 'repositories/identity'
import { isEmpty } from 'lodash'

export const getUsers = actions$ => actions$
  .pipe(ofType(slice.actions.attempt))
  .pipe(mergeMap(action => !isEmpty(action.payload.addresses) ? execute(action) : success([])))

const execute = ({ payload }) => identity().multipleUsers(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  slice.actions.success(payload),
  slice.actions.append(payload),
])

const failure = payload => of(slice.actions.failure(payload))

