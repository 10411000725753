import { DappProvider } from '@elrondnetwork/dapp-core/wrappers'
import React from 'react'
import { useDappConfig } from 'settings/hooks/use-dapp-config'
import { get } from 'lodash'
import { settings } from 'settings/fetching'

const delay = () => get(settings(), 'postSuccessTxDelay', 2000)

const useProviderProps = () => ({
  customNetworkConfig: useDappConfig().network,
  completedTransactionsDelay: delay(),
  environment: process.env.REACT_APP_NODE_ENV === 'staging' ? 'mainnet' : process.env.REACT_APP_NODE_ENV,
})

export const DappProviderWrapper = ({ children }) => <DappProvider {...useProviderProps()}>
  {children}
</DappProvider>
