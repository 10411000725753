import 'nft-page/media/image/index.scss'
import { useImageSource } from 'nft-page/media/image/hooks/use-image-source'
import { useCurrentAsset } from 'nft-page/media/hooks/use-current-asset'
import { useEffect, useRef } from 'react'

export const Image = () => {
  const currentAsset = useCurrentAsset()
  const source = useImageSource(currentAsset)
  const imgRef = useRef()
  const imgBlurRef = useRef()

  useEffect(() => {
    if (imgRef) {
      imgRef.current.removeAttribute('src')
      imgRef.current.src = source
      imgBlurRef.current.removeAttribute('src')
      imgBlurRef.current.src = source
    }
  }, [source])

  return <div className={'nft-file-image d-flex justify-content-center align-items-center'}>
    <div className={'nft-file-image-wrapper'}>
      <img className={'nft-image'} ref={imgRef} />
      <img className={'nft-image-blur'} ref={imgBlurRef} />
    </div>
  </div>
}
