import { Item } from 'components/top-navigation/components/search-form/search-results/item'
import { useTopNavigationContext } from 'components/top-navigation/context'

export const Content = ({ items, searchPhrase }) => {
  const { searchResultsOpen, selectedResult } = useTopNavigationContext()
  const displayClass = searchResultsOpen ? '' : 'd-none'

  return <div className={`search-results ${displayClass}`}>
    {items.map((item, index) => <Item key={index} item={item} searchPhrase={searchPhrase}
                                      selected={index === selectedResult} />)}
  </div>
}
