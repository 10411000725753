import { useContent } from 'holoride-campaign/action/holoride-flow/hooks/use-content'
import { statuses } from 'holoride-campaign/action/holoride-flow/statuses'
import { useIsWhitelisted } from 'holoride-campaign/hooks/use-is-whitelisted'
import { useMyTickets } from 'holoride-campaign/hooks/use-my-tickets'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { usePrimarySale } from 'holoride-campaign/hooks/use-primary-sale'
import './index.scss'
import { useHasClaimedTickets } from 'holoride-campaign/hooks/use-has-claimed-tickets'
import { Behaviour } from 'holoride-campaign/action/behaviour'

export const Action = () => {
  const primarySale = usePrimarySale()
  const isWhitelisted = useIsWhitelisted()
  const myTickets = useMyTickets()
  const { address } = useGetAccountInfo()
  const hasClaimedTickets = useHasClaimedTickets()

  const Content = useContent({
    primarySale,
    isWhitelisted,
    address,
    myTickets,
    hasClaimedTickets,
    statuses: statuses(),
  })

  return <div className={'holoride-action'}>
    <Behaviour />
    <Content primarySale={primarySale} isWhitelisted={isWhitelisted} address={address} myTickets={myTickets} />
  </div>
}
