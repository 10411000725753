import { useGetSignedTransactions } from '@elrondnetwork/dapp-core/hooks'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useMemo } from 'react'

const mapStatus = statuses => identifier => {
  if (identifier.includes('undefined')) {
    return get(Object.values(statuses.actions), '0')
  }

  return get(statuses, `actions[${identifier}]`)
}

const fromIdentifiers = (identifiers, { actionStatuses }) => identifiers.map(mapStatus(actionStatuses))

const exists = value => value

export const useActionStatus = identifiers => {
  const sessionIds = useSelector(state => fromIdentifiers(identifiers, state).filter(exists))
  const { signedTransactions } = useGetSignedTransactions()

  return useMemo(() => sessionIds.map(sessionId => ({
    sessionId,
    status: get(signedTransactions, `[${sessionId}].transactions[0].status`),
  })), [sessionIds, signedTransactions])
}
