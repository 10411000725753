import { uniqBy } from 'lodash'
import { ViewOnOtherMarket } from 'nft-page/offers/actions/view-on-other-market'

export const List = ({ offers }) => {
  const offersMarketplace = offers.map(({ node }) => node.marketplace)
  const actualList = uniqBy(offersMarketplace, 'key')

  return <div className={'nft-page-offers-markets'}>
    {actualList.map((item, index) => <ViewOnOtherMarket marketplace={item} key={index} />)}
  </div>
}
