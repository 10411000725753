import { settings } from 'settings/fetching'
import { includes } from 'lodash'
import { newTransaction } from '@elrondnetwork/dapp-core/models'

const checkForContractAddress = (data, marketplaceAddress, cmsCampaignContract) => {
  const { systemSmartContract } = settings()
  const addresses = [systemSmartContract, marketplaceAddress, cmsCampaignContract]

  if (!includes(addresses, data.receiver)) {
    throw Error('Contract not matching Configuration')
  }
}

export const makeTransaction = (data, marketplaceAddress, cmsCampaignContract) => {
  if (data.sender !== data.receiver) {
    checkForContractAddress(data, marketplaceAddress, cmsCampaignContract)
  }

  return newTransaction(data)
}
