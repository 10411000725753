import { Seo } from 'components/seo'
import { Content } from 'feed/content'
import { MegaAuthorized } from 'auth/mega-authorized'
import 'feed/index.scss'

export const Feed = () => <>
  <Seo title={'Feed'} />
  <MegaAuthorized>
    <Content />
  </MegaAuthorized>
</>

export * from 'feed/reducer'
export * from 'feed/epic'
export * as suggestions from 'feed/suggestions'
