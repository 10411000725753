import './index.scss'
import { Formik } from 'formik'
import { Behaviour } from 'components/top-navigation/components/search-form/behaviour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { SearchResults } from 'components/top-navigation/components/search-form/search-results/index'
import { useOnSubmit } from 'components/top-navigation/components/search-form/hooks/use-on-submit'
import { Input } from 'components/top-navigation/components/search-form/input'

export const SearchForm = () => {
  const onSubmit = useOnSubmit()

  return <Formik onSubmit={onSubmit} initialValues={{ search: '' }}>
    {props => (<form onSubmit={props.handleSubmit} className={'w-100'}>
      <Behaviour />
      <div className={'search-input-wrapper'}>
        <Input formikProps={props} />
        <SearchResults searchPhrase={props.values.search} />
        <FontAwesomeIcon className={'search-input-icon'} icon={faSearch} onClick={props.handleSubmit} />
      </div>
    </form>)}
  </Formik>
}
