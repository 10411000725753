import { useEconomics } from 'economics/hooks/use-economics'
import { useCloseSidebar } from 'sidebar/hooks/use-close-sidebar'
import { useViewportWidth } from 'startup/hooks/use-viewport-width'
import { useKeysControls } from 'startup/hooks/use-keys-controls'
import { useHydrateLocation } from 'startup/hooks/use-hydrate-location'
import { useTransactionRedirect } from 'startup/hooks/use-transaction-redirect'
import { useCurrentProfile } from 'current-profile/hooks/use-current-profile'

export const Behaviour = () => {
  useEconomics()
  useCloseSidebar()
  useViewportWidth()
  useKeysControls()
  useHydrateLocation()
  useTransactionRedirect()
  useCurrentProfile()

  return ''
}
