import { useOnChange } from 'components/top-navigation/components/search-form/hooks/use-on-change'
import { createContext, useContext, useState } from 'react'

export const TopNavigationContext = createContext({
  searchOpen: false,
  searchResultsOpen: false,
})

export const useTopNavigationContext = () => useContext(TopNavigationContext)

export const useDefaultContext = () => {
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchResultsOpen, setSearchResultsOpen] = useState(false)
  const [selectedResult, setSelectedResult] = useState(-1)
  const onChange = useOnChange()

  return {
    searchOpen,
    setSearchOpen,
    onChange,
    searchResultsOpen,
    setSearchResultsOpen,
    selectedResult,
    setSelectedResult,
  }
}

