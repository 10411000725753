import { useLocation, useNavigate } from 'react-router-dom'
import { useShouldHydrate } from 'startup/hooks/use-hydrate-location/use-should-hydrate'
import { useMakeTargetState } from 'startup/hooks/use-hydrate-location/use-make-target-state'
import { useEffect } from 'react'

export const useHydrateLocation = () => {
  const { pathname, search, state } = useLocation()
  const navigate = useNavigate()
  const shouldHydrate = useShouldHydrate()
  const makeTargetState = useMakeTargetState()

  useEffect(() => {
    const targetState = makeTargetState()

    if (shouldHydrate && targetState) {
      navigate({ pathname, search }, { state: { ...targetState, hydrated: true }, replace: true })
    }
  }, [shouldHydrate, pathname, navigate, search, state, makeTargetState])
}

export * from 'startup/hooks/use-hydrate-location/use-should-hydrate'
