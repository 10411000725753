import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useCloseMenu = () => {
  const location = useLocation()

  useEffect(() => {
    const toggler = document.querySelector('.navbar-toggler')
    const navbar = document.querySelector('.navbar-collapse')

    if (navbar?.classList.contains('show')) {
      toggler.click()
    }
  }, [location])
}
