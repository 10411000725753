import { Navigate } from 'react-router-dom'
import { useFirstExactMatch } from 'search/hooks/use-first-exact-match'

const makeAccountLink = ({ item }) => item.name ? `/@${item.name.split('.')[0]}` : `/${item.identifier}`

export const HerotagRedirect = () => {
  const result = useFirstExactMatch()

  if (result?.kind === 'account') return <Navigate to={makeAccountLink(result)} />

  return ''
}
