import { from } from 'rxjs'
import { history } from 'network'
import { ofType } from 'redux-observable'
import { accounts } from 'repositories/accounts'
import { mergeMap, catchError } from 'rxjs/operators'
import { failure as failureSlice } from 'failure/slices'
import { address as slice } from 'viewer/slices/address'
import { isHeroTag } from 'viewer/hooks/use-viewer-fetching'

export const address = actions$ => actions$
  .pipe(ofType(slice.actions.attempt.type))
  .pipe(mergeMap(chooseFlow))

const chooseFlow = ({ payload }) => {
  if (isHeroTag(payload) && payload.charAt(0) === '@') return fetchHerotag(payload.substring(1))

  return fetchAddress(payload)
}

const fetchHerotag = payload => accounts.byUsername(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const fetchAddress = payload => accounts.byAddress(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  slice.actions.success(payload),
])

const failure = payload => from([
  slice.actions.failure(payload),
  failureSlice.actions.show({ route: history.location.pathname }),
])
