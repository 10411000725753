import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'search/slices/dynamic'

export const useDynamicSearchReset = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.reset({}))
  }, [dispatch])
}
