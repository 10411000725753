import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  attempt: '',
  data: '',
  errors: '',
}

const attempt = (state, { payload }) => ({ ...state, attempt: payload })

const success = (state, { payload }) => ({ ...state, data: payload })

const failure = (state, { payload }) => ({ ...state, errors: payload })

const cleanUp = () => initialState

export const followers = createSlice({
  initialState,
  name: 'account/followers/count',
  reducers: {
    attempt,
    success,
    failure,
    cleanUp,
  },
})
