import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { actions } from 'sidebar/stats/slices/get'
import { useAppContext } from 'startup/context'
import { useCmsMarketplaceKey } from '../../../subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useFetchSidebarStats = () => {
  const { address } = useGetAccountInfo()
  const dispatch = useDispatch()
  const { sidebarOpened } = useAppContext()
  const marketplaceKey = useCmsMarketplaceKey()
  useEffect(() => {
    if (address && sidebarOpened) {
      dispatch(actions.attempt({ filters: { address, isOwner: true, marketplaceKey } }))
    }
  }, [address, dispatch, sidebarOpened, marketplaceKey])
}
