import { fromFetch } from 'rxjs/fetch'
import { auth } from 'network/rest/auth'
import { mergeMap } from 'rxjs/operators'
import { internal } from './internal'

const METHOD = 'GET'

const authorizedGet = (url, options) => auth.makePayload(options)
  .pipe(mergeMap(payload => fromFetch(url, { method: METHOD, ...payload })))

const nonAuthorizedGet = (url, options) => internal.makePayload(options)
  .pipe(mergeMap(payload => fromFetch(url, { method: METHOD, ...payload })))

export const get = () => (url, options = {}, authorize = false) => {
  if (authorize) return authorizedGet(url, options)

  return nonAuthorizedGet(url, options)
}
