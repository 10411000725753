import 'nft-page/report-button/index.scss'
import { useIsLoading } from 'report/hooks/use-is-loading'
import { icons } from 'nft-page/report-button/icons'
import { useReport } from 'report/hooks/use-report'
import { useAddReport } from 'report/hooks/use-add-report'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'

const useGetReportData = () => {
  const data = useReport()
  const reported = { icon: icons.check, text: 'Reported', disabled: true }
  const report = { icon: icons.report, text: 'Report', disabled: false }

  return data ? reported : report
}

export const ReportButton = () => {
  const { address } = useGetAccountInfo()
  const addReport = useAddReport()
  const onClick = usePermittedAction(addReport, address)
  const report = useGetReportData()

  return <button className={'btn report-button'} onClick={onClick} disabled={useIsLoading() || report.disabled}>
    {report.icon}
    {report.text}
  </button>
}

