import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { actions } from 'economics/slices/economics'
import { mergeMap, catchError } from 'rxjs/operators'
import { economics as api } from 'repositories/economics/index'

export const economics = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = () => api.get()
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(result => success(result)))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = errors => of(actions.failure(errors))
