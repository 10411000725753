import './description.scss'

export const Description = () => <div className={'footer-description mt-3 px-4 ps-lg-0'}>
  <p className={'fw-bolder mb-2'}>
    Own a piece of Web3 culture.
  </p>
  <p>
    The front-page of the MultiversX NFT ecosystem. Create, buy and sell NFTs.
    Collaborate with fellow collectors and creators.
    Be part of the genesis of Web3 culture and own a piece of it.
  </p>
</div>
