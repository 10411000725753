import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { identity } from 'repositories/identity'
import { mergeMap, catchError } from 'rxjs/operators'
import { actions } from 'current-profile/slices/current-profile'

export const currentProfile = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => identity().selfInfo(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = errors => of(actions.failure(errors))
