import { useCallback } from 'react'
import { useOwnerAddress } from 'owners/hooks/use-owner-address'
import { useOwnerHeroTag } from 'owners/hooks/use-owner-herotag'

const getModalRoute = () => window.location.pathname.split('/modal')[1] ? window.location.pathname : ''

const getForegroundRoute = () => window.location.pathname.split('/modal')[0]

const getBackgroundRoute = (address, herotag) => `/${herotag ? `@${herotag}` : address}`

const makeState = (ownerAddress, ownerHeroTag) => ({
  layerLink: {
    modal: getModalRoute(),
    foreground: getForegroundRoute(),
    background: getBackgroundRoute(ownerAddress, ownerHeroTag),
  },
})

export const useMakeNftState = () => {
  const ownerAddress = useOwnerAddress()
  const ownerHeroTag = useOwnerHeroTag()

  return useCallback(() => ownerAddress && makeState(ownerAddress, ownerHeroTag), [ownerAddress, ownerHeroTag])
}
