import { useCallback } from 'react'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { Loading } from 'loading/index'
import { sanitizeStrict } from 'common/hooks/sanitize-strict'

export const useSetSearchParam = () => {
  const navigate = useLayeredNavigate()
  const showLoading = Loading.useShowLoading()

  return useCallback(search => {
    const safeSearch = sanitizeStrict(search)

    if (safeSearch) {
      const pathname = `/search/${safeSearch}`

      showLoading({}, { id: Loading.ID.search.search, variant: Loading.VARIANT.partial })
      navigate(pathname, 'middleground')
    }
  }, [showLoading, navigate])
}

