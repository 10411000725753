import { useTopNavigationContext } from 'components/top-navigation/context'
import { useCallback } from 'react'

export const useHandleFocus = () => {
  const { setSearchResultsOpen } = useTopNavigationContext()

  return useCallback(e => {
    setSearchResultsOpen(true)
  }, [setSearchResultsOpen])
}

