import { fromFetch } from 'rxjs/fetch'
import { auth } from 'network/rest/auth'
import { mergeMap } from 'rxjs/operators'
import { internal } from './internal'

const METHOD = 'POST'

const authorizedPost = (url, options) => auth.makePayload(options)
  .pipe(mergeMap(payload => fromFetch(url, { ...payload, method: METHOD })))

const nonAuthorizedPost = (url, options) => internal.makePayload(options)
  .pipe(mergeMap(payload => fromFetch(url, { ...payload, method: METHOD })))

export const post = () => (url, options = {}, authorized = false) => {
  if (authorized) return authorizedPost(url, options)

  return nonAuthorizedPost(url, options)
}
