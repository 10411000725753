import match from '@nerds.sh/js-pattern'
import { useCallback } from 'react'

const makeAccountLink = item => item.name ? `/@${item.name.split('.')[0]}` : `/${item.identifier}`

export const useMakeLink = () => useCallback(item => match(item)
  .like({ kind: 'account' }, () => makeAccountLink(item.item))
  .like({ kind: 'tag' }, () => `/nfts?tag=${item.item.identifier}`)
  .like({ kind: 'collection' }, () => `/collections/${item.item.identifier}`)
  .like({ kind: 'asset' }, () => `/nfts/${item.item.identifier}`)
  .otherwise(() => ''), [])
