import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'modals/collection/set-roles/slices/set-roles'
import { useParams } from 'react-router-dom'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'

export const useSetRoles = () => {
  const { identifier } = useParams()
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()

  return useCallback(payload => {
    dispatch(actions.attempt({ collection: identifier, addressToTransfer: address, roles: payload.roles }))
  }, [identifier, address, dispatch])
}
