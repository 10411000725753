import { from, of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { actions } from 'modals/account/edit/social-links/slices/update-social-links'
import { identity } from 'repositories/identity'

export const updateSocialLinks = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => identity().self.setSocialLink(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = errors => of(actions.failure(errors))
