import { useCallback } from 'react'

const getModalRoute = () => window.location.pathname.split('/modal')[1] ? window.location.pathname : ''

const getBackgroundRoute = () => window.location.pathname.split('/modal')[0]

const makeState = () => ({
  layerLink: {
    modal: getModalRoute(),
    background: getBackgroundRoute(),
  },
})

export const useMakeAccountState = () => useCallback(() => makeState(), [])
