import { Self } from './self'
import { getButtonClassName } from './get-button-class-name'
import { forwardRef } from 'react'
import './button.scss'

export const Button = forwardRef(({ icon, onClick, to, className, children, ...extra }, ref) => {
  const buttonClass = getButtonClassName({ className, icon, ...extra })

  return (
    <Self ref={ref} icon={icon} onClick={onClick} to={to} className={buttonClass} {...extra}>
      {children}
    </Self>
  )
})

