import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { follow } from 'account/profile/follow/slices/follow'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'

export const useFollow = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()
  const { address: loggedInAddress } = useGetAccountInfo()

  return useCallback(() => {
    address && dispatch(follow.actions.attempt({ address, loggedInAddress }))
  }, [dispatch, address, loggedInAddress])
}
