import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useAuctionPath } from 'startup/hooks/use-hydrate-location/use-auction-path'

export const useAuctionOwnerAddress = () => {
  const path = useAuctionPath()
  const address = useSelector(state => get(state, `${path}.ownerAddress`, ''))
  const ownerHerotag = useSelector(state => get(state, `${path}.owner.herotag`, ''))

  return ownerHerotag ? `@${ownerHerotag?.split('.')[0]}` : '' || address
}
