import { useEffect } from 'react'
import { useFetchAssetPayload } from 'tags/hooks/use-fetch-asset-payload'
import { useDispatch } from 'react-redux'
import { actions } from 'tags/slices/asset'

export const useFetchAssetTags = () => {
  const dispatch = useDispatch()
  const payload = useFetchAssetPayload()

  useEffect(() => {
    dispatch(actions.attempt(payload))
  }, [dispatch, payload])
}
