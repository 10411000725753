import match from '@nerds.sh/js-pattern'
import { ReactComponent as Human } from 'startup/assets/human.svg'
import { ReactComponent as Hash } from 'startup/assets/hash.svg'
import { ReactComponent as Picture } from 'startup/assets/picture.svg'
import { ReactComponent as Star } from 'startup/assets/star2.svg'
import { makeHtml, Title } from 'components/top-navigation/components/search-form/search-results/item/title'
import 'components/top-navigation/components/search-form/search-results/item/index.scss'
import { Link } from 'components/top-navigation/components/search-form/search-results/item/link'
import { highlightText } from '../../../../../../common/highlight-text'

const getIcon = item => match(item)
  .like({ kind: 'account' }, () => Human)
  .like({ kind: 'tag' }, () => Hash)
  .like({ kind: 'collection' }, () => Picture)
  .like({ kind: 'asset' }, () => Star)
  .otherwise(() => () => '')

const getSubtitle = (item, searchPhrase) => match(item)
  .like({ kind: 'account' }, () => `${item.item.identifier}`)
  .like({ kind: 'tag' }, () => 'Hashtag')
  .like({ kind: 'collection' }, () => makeHtml(highlightText(searchPhrase, `${item.item.identifier} Collection`)))
  .like({ kind: 'asset' }, () => makeHtml(highlightText(searchPhrase, `${item.item.identifier} NFT`)))
  .otherwise(() => '')

export const Item = ({ item, selected, searchPhrase }) => {
  const Icon = getIcon(item)

  return (
    <div className={`search-results-item type-${item.kind} ${selected ? 'selected' : ''}`}>
      <Link item={item} />
      <div className={'search-results-item-icon'}><Icon /></div>
      <div className={'search-results-item-content'}>
        <Title item={item} searchPhrase={searchPhrase} />
        <div className={'search-results-item-subtitle'}>
          {getSubtitle(item, searchPhrase)}
        </div>
      </div>
    </div>
  )
}
