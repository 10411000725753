import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './search-toggle.scss'
import { TopNavigationContext } from '../../context'
import { useContext } from 'react'

export const SearchToggle = () => {
  const { searchOpen, setSearchOpen } = useContext(TopNavigationContext)

  const onClick = () => setSearchOpen(~searchOpen)

  return <button className={'d-flex align-items-center d-lg-none search-toggle py-2'} onClick={onClick}>
    <FontAwesomeIcon icon={faSearch} />
  </button>
}
