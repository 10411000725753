import { combineLatest, from, of } from 'rxjs'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { actions } from 'modals/account/photo/slices/update-profile-photo'
import { actions as profileActions } from 'account/profile/slices/profile'
import { actions as currentProfileActions } from 'current-profile/slices/current-profile'
import { identity } from 'repositories/identity'
import { getAddress } from '@elrondnetwork/dapp-core/utils'

const refreshProfile = () => from(getAddress()).pipe(map(address => profileActions.attempt(address)))

const refreshCurrentProfile = () => from(getAddress()).pipe(map(address => currentProfileActions.attempt(address)))

export const updateProfilePhoto = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => identity().self.setProfilePhoto(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const successActions = payload => [
  refreshProfile(),
  refreshCurrentProfile(),
  of(actions.success(payload)),
]

const success = payload => combineLatest(successActions(payload))
  .pipe(mergeMap(actions => actions))

const failure = errors => from([
  actions.failure(errors),
])
