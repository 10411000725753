import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { unfollow } from 'account/profile/follow/slices/unfollow'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useIsPrinceCharming } from 'auth/mega-authorized'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'

export const useUnfollow = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()
  const isPrinceCharming = useIsPrinceCharming()
  const { address: loggedInAddress } = useGetAccountInfo()

  return useCallback(() => {
    isPrinceCharming && address && dispatch(unfollow.actions.attempt({ address, loggedInAddress }))
  }, [dispatch, address, isPrinceCharming, loggedInAddress])
}
