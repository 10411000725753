import { useOnChange } from 'components/top-navigation/components/search-form/hooks/use-on-change'
import { useCallback } from 'react'

export const useHandleChange = () => {
  const onChange = useOnChange()

  return useCallback((e, props) => {
    props.handleChange(e)
    onChange(e.currentTarget.value)
  }, [onChange])
}
