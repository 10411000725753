import { faSearchPlus } from '@fortawesome/pro-light-svg-icons'
import { Empty } from 'components/empty'
import { useSearchFromParams } from 'search/hooks/use-search-from-params'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useNotFound } from 'search/hooks/use-not-found'
import { useSearchParam } from 'search/hooks/use-search-param'

const makeSearchProps = searchTerm => ({
  icon: faSearchPlus,
  message: <>
    No results found for
    {' '}
    <strong>{searchTerm}</strong>
    .
  </>,
  fullscreen: true,
})

const useClearState = () => {
  const notFound = useNotFound()
  const { pathname, state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (notFound && state) {
      navigate(pathname, { replace: true })
    }
  }, [pathname, navigate, state, notFound])
}

export const NoSearchResults = () => {
  useSearchFromParams()
  useClearState()
  const notFound = useNotFound()
  const searchTerm = useSearchParam()

  return notFound ? <Empty {...makeSearchProps(searchTerm)} /> : ''
}
