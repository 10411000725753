import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useAuthorizedAction } from 'common/hooks/use-authorized-action'

export const AuthorizedLink = ({ link }) => {
  const navigate = useNavigate()

  const onNavigate = useCallback(() => navigate(link.href), [link.href, navigate])

  const authorizedAction = useAuthorizedAction(onNavigate)

  return <div className={'footer-section-link'} onClick={authorizedAction}>
    {link.title}
  </div>
}
