import { useNavigate, useLocation } from 'react-router-dom'
import { useAppContext } from 'startup/context'
import { useCallback } from 'react'

export const useOnClick = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { setShouldRefresh } = useAppContext()

  return useCallback(e => {
    e.preventDefault()

    if (pathname === '/') {
      setShouldRefresh(true)
    } else {
      navigate('/')
    }
  }, [pathname, navigate, setShouldRefresh])
}
