import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons'
import 'nft-page/offers/actions/view-on-other-market.scss'

const makeProps = marketplace => ({
  className: 'offer-link-button btn btn-outline-primary btn-lg',
  target: '_blank',
  rel: 'noreferrer',
  href: get(marketplace, 'url', ''),
})

export const ViewOnOtherMarket = ({ marketplace }) => {
  const marketplaceName = get(marketplace, 'name', '')

  return <div>
    <a {...makeProps(marketplace)}>
      <FontAwesomeIcon icon={faExternalLink} />
      {' '}
      {`View `}
      <span className={'d-none d-sm-inline'}>offers</span>
      {` on ${marketplaceName}`}
    </a>
  </div>
}
