import { useFlagNft, useFlagCollection } from 'admin/hooks/actions'

export const useActionConfig = () => {
  const flagNft = useFlagNft()
  const flagCollection = useFlagCollection()

  return {
    markNftNsfw: {
      label: 'Mark NFT NSFW',
      onClick: flagNft,
    },
    markCollectionNsfw: {
      label: 'Mark Collection NSFW',
      onClick: flagCollection,
    },
  }
}
