import { toArray, map } from 'rxjs'
import { raw } from 'repositories/feed/suggestions/get/raw'
import { removeFailing } from 'repositories/feed/suggestions/get/remove-failing'
import { accountNotPrivate } from 'repositories/feed/suggestions/get/account-not-private'
import { notOwnAccount } from 'repositories/feed/suggestions/get/not-own-account'
import { shuffle } from 'lodash'
import { take } from 'repositories/feed/suggestions/get/take'
import { followedConditionByType } from 'repositories/feed/suggestions/get/followed-condition-by-type'

export const get = ({ address, type }) => raw()
  .pipe(removeFailing(followedConditionByType(type)))
  .pipe(removeFailing(accountNotPrivate))
  .pipe(removeFailing(notOwnAccount(address)))
  .pipe(toArray())
  .pipe(map(shuffle))
  .pipe(map(take))
