import { ofType } from 'redux-observable'
import { follows as followsSlice } from 'account/profile/follow/slices/follows'
import { followers } from 'repositories/followers'
import { from, catchError, mergeMap } from 'rxjs'

export const follows = actions$ => actions$
  .pipe(ofType(followsSlice.actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => followers.get.follows({ address: payload.address })
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = response => from([
  followsSlice.actions.success(response),
])

const failure = response => from([
  followsSlice.actions.failure(response),
])
