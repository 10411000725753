import { Content } from 'trending/content'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Seo } from 'components/seo'
import 'trending/index.scss'

export const Trending = () => <div className={'trending-page'}>
  <Seo title={'Trending'} />
  <ScrollToTopOnMount />
  <Content />
</div>
