import { useTopNavigationContext } from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './search-overlay.scss'
import { SearchForm } from 'components/top-navigation/components/search-form'

export const SearchOverlay = () => {
  const { searchOpen, setSearchOpen } = useTopNavigationContext()

  const onClick = () => {
    setSearchOpen(false)
  }

  const visible = () => searchOpen ? 'd-flex' : 'd-none'
  const className = `navbar navbar-expand justify-content-start navbar-light search-overlay ${visible()}`

  return <nav className={className}>
    <div className={'container'}>
      <SearchForm />
      <div className={'ms-3 me-1'} onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  </nav>
}
