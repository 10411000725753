import { Controls } from 'nft-page/controls'
import { useComponent } from 'nft-page/media/hooks/use-component'
import { useSwipeControls } from 'nft-page/media/hooks/use-swipe-controls'
import { useCurrentAsset } from 'nft-page/media/hooks/use-current-asset'
import { ReportButton } from 'nft-page/report-button'
import { ScamChecker } from 'nft-page/media/scam-checker'
import 'nft-page/media/index.scss'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'
import { NotSafeForWorkChecker } from 'nft-page/media/not-safe-for-work-checker'

export const Media = () => {
  const currentAsset = useCurrentAsset()
  const Component = useComponent(currentAsset)
  const navigate = useCloseLayerNavigate()

  return <div {...useSwipeControls()} className={'nft-media'}>
    <div className={'nft-media-background'} onClick={() => navigate()} />
    <Controls />
    <ScamChecker asset={currentAsset}>
      <NotSafeForWorkChecker asset={currentAsset}>
        <Component />
      </NotSafeForWorkChecker>
    </ScamChecker>
    <ReportButton />
  </div>
}
