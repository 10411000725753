import { faFacebookSquare, faInstagram, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { SocialLink } from 'components/footer/brand/social-links/social-link'

export const SocialLinks = () => (
  <div className={'footer-social-links d-flex justify-content-center justify-content-lg-start mt-4'}>
    <SocialLink icon={faTwitter} url={'https://twitter.com/MultiversX'} />
    <SocialLink icon={faFacebookSquare} url={'https://www.facebook.com/MultiversX/'} />
    <SocialLink icon={faInstagram} url={'https://www.instagram.com/multiversxofficial/'} />
    <SocialLink icon={faTelegram} url={'https://t.me/multiversx'} />
  </div>
)
