import { useFormikContext } from 'formik'
import { useCallback, useEffect } from 'react'
import { useTopNavigationContext } from 'components/top-navigation/context'
import { useSearchReset } from 'search/hooks/use-search-reset'
import { useLocation } from 'react-router-dom'

export const useResetSearch = () => {
  const { setFieldValue } = useFormikContext()
  const { setSelectedResult } = useTopNavigationContext()
  const location = useLocation()

  const resetSearch = useSearchReset()

  const resetCallback = useCallback(() => {
    setFieldValue('search', '')
    setSelectedResult(-1)
    resetSearch()
  }, [resetSearch, setSelectedResult, setFieldValue])

  useEffect(() => {
    resetCallback()
  }, [resetCallback, location])
}
