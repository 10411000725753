import { useCallback, useEffect } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useDispatch } from 'react-redux'
import { actions } from 'account/stats/slices/get'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useCmsMarketplaceKey } from '../../../subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useFetchStatsCallback = () => {
  const viewerAddress = useViewerAddress()
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()
  const isOwner = viewerAddress === address
  const marketplaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    if (viewerAddress) {
      dispatch(actions.attempt({ filters: { address: viewerAddress, isOwner, marketplaceKey } }))
    }
  }, [viewerAddress, dispatch, isOwner, marketplaceKey])
}

export const useFetchStats = () => {
  const callback = useFetchStatsCallback()

  useEffect(callback, [callback])
}
