import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { transactions } from 'repositories/transactions'
import { decodeTransactionData } from 'common/decode-transaction-data'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { actions } from 'collections/slices/look-up-transaction'

export const lookup = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => transactions.byHash(payload)
  .pipe(mergeMap(payload => from(payload.json())))
  .pipe(map(decodeTransactionData))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = errors => of(actions.failure(errors))
