// eslint-disable-next-line max-lines-per-function
export const menuSections = address => [{
  title: 'Essentials',
  links: [
    {
      title: 'Get EGLD',
      href: 'https://buy.multiversx.com',
      external: true,
    },
    {
      title: 'xPortal Mobile Wallet',
      href: 'https://xportal.com',
      external: true,
      freeLabel: true,
    },
    {
      title: 'MultiversX DeFi Wallet',
      href: 'https://chrome.google.com/webstore/detail/multiversx-defi-wallet/dngmlblcodfobpdpecaadgfbcggfjfnm',
      external: true,
      freeLabel: true,
    },
    {
      title: 'MultiversX Explorer',
      href: 'https://explorer.multiversx.com',
      external: true,
    },
  ],
},
{
  title: 'My Account',
  links: [
    {
      title: 'My NFTs',
      href: `/${address}`,
      authorized: true,
    },
    {
      title: 'My Collections',
      href: `/${address}/collections`,
      authorized: true,
    },
    {
      title: 'Web3 Feed',
      href: `/feed`,
      authorized: true,
    },
    {
      title: 'Auctions',
      href: `/${address}/auctions`,
      authorized: true,
    },
  ],
},
{
  title: 'Discover',
  links: [
    {
      title: 'Kickstart your NFT journey',
      href: `/kickstart`,
    },
    {
      title: 'Learn about NFTs',
      href: '/learn',
    },
    {
      title: 'Create your first NFT',
      href: '/create',
    },
    {
      title: 'Master your bid game',
      href: '/master',
    },
  ],
},
{
  title: 'Legal',
  links: [
    {
      title: 'Privacy Policy',
      href: '/privacy',
    },
    {
      title: 'Terms & Conditions',
      href: '/terms',
    },
    {
      title: 'Security',
      href: 'https://multiversx.com/report/',
      external: true,
    },
  ],
}]
