import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'

/* eslint-disable max-lines-per-function, max-len, max-lines */
export const Privacy = () => <div className={'container mb-5'}>
  <ScrollToTopOnMount />
  <h1 className={'text-center'}>Privacy Policy</h1>
  <p>At Inspire.Art, accessible from https://inspire.art/, one of our main priorities is the privacy of our visitors and users. This privacy policy document (the “Privacy Policy”) together with our Terms of Service of this website and any other documents we mention in this policy is meant to inform you on which personal data about you we collect, use, disclose, share or otherwise process when you are using this website, our products and our services. Please read this policy carefully to understand our views and practices on how we protect your personal data.</p>
  <p>If you have additional questions or require more information about our Privacy Policy, please do not hesitate to contact us.</p>

  <h2>Introduction</h2>
  <p>This is the privacy statement of Elrond Network SRL ( “Company”, “Elrond”, “we”, “us”, “our“), a Elrond group company. Elrond and our existing or future affiliates (collectively “Elrond” or “we”) are the controllers with respect to your data. This Privacy Policy applies to Inspire.Art website.</p>
  <p>Elrond cares about protecting your right to privacy in all the regions where we operate and while complying with local laws, we are committed to implementing the personal data protection standard imposed by the GDPR across all Elrond entities.</p>
  <p>As a general rule, users of our online services are anonymous to us, and we have no feasible ways to identify you. However certain categories of data may be collected while using our services, and some of this information may be considered “personal data” by the law. When we collect personal data, we generally act as a “data controller” as defined in applicable law. The exact categories of data we collect, why we collect it, and your opt-out choices depend on the service you use, as is more fully described below.</p>
  <p>When we post changes to this Privacy Policy, we will include the date when the statement was last updated. If we significantly change this Privacy Policy, we will notify you about the upcoming change via our website. We encourage you to review this statement periodically.</p>

  <h2>Children’s Privacy</h2>
  <p>Inspire.Art is not intended for children under the age of 18. We do not knowingly or intentionally collect information about children who are under 18 years of age.</p>
  <p>IF YOU ARE UNDER THE AGE OF 18 YOU MAY NOT USE OUR COMPANY SERVICES IN ANY WAY.</p>

  <h2>General</h2>
  <p>This Privacy Policy sets out the basis on which any information and/or data of the Users, is collected, used and disclosed by the Company when accessing or using the Company Services. The Company is committed to preserving the privacy of all visitors of the website.</p>
  <p>The Policy explains how the Company complies with the General Data Protection Regulation (‘GDPR’).</p>
  <p>By proceeding further, you have read and expressly consent to the collection, use, retention, processing, transfer and disclosure of Personal Data under the terms of this Privacy Policy.</p>
  <p>Information and/or Personal Data which may be collected, stored, processed, transferred, used and disclosed by Elrond includes but is not limited to information and/or data that can be used on its own or with other information to, directly or indirectly, identify, contact, or locate a single person, or to identify an individual in context.</p>

  <h2>Definitions</h2>
  <p>The following terms “Controller”, “Processor”, “Data Subject”, “Personal Data”, “Processing Activity/ies”, “Pseudonymisation”, “Cross-Border processing of Personal Data”, “Supervisory Authority” used in this document shall have the same meaning as in the GDPR.</p>
  <p>Other Capitalised terms used in this Privacy Policy shall have the same meaning given to them in the User Agreement and Terms.</p>

  <h2>What do we understand by Personal Data?</h2>
  <p>Personal data is the information related to a natural person and which can be used to identify that person such as name, email address, telephone number, social media usernames, location data or business titles. This means that when you use the Company Services, we will collect certain information about you which can identify you directly (name, email) or indirectly (IP address or other online identifier). We also collect anonymous information and aggregated data regarding user behavior, navigational information, device information, which helps us tailor our services and understand usage trends.</p>

  <h2>Basic Principles Regarding Personal Data Processing</h2>
  <p>Elrond, as Controller, shall adhere to the GDPR principles as stipulated in Article 5(2) of the GDPR where the “Controller shall be responsible for, and be able to demonstrate, compliance with the principles.” The Personal Data must be processed fairly and lawfully; be obtained only for specific and lawful matters; be adequate and up to date; not to be held for any longer than necessary; and be protected in appropriate ways.</p>
  <p>The Company shall be accountable and must disclose information held to the Data Subject when requested and shall not transfer Personal Data outside of the European Economic Area (EEA), unless that country or territory also ensures an “adequate” level of protection. The Processing of Data must be carried out in accordance with the rights of the Data Subjects.</p>

  <h2>The Company May Collect the Following Information</h2>
  <p>Information provided by the User: when using the Company Services, registering your account, subscribing to Inspire.Art’s updates, responding to a survey or when filling enquiry forms on our website(s) and when corresponding by phone, e-mail or other communication services, you may be asked to provide certain information. The information may include Personal Data such as name, address, e-mail address, phone number, financial and/or credit card information, cryptocurrency address, personal description, ID or Passport copy, date of birth, passport number, or other data which can be used for personal identification purposes and required for ‘know your customer’ that may be required to comply with applicable laws.</p>
  <p>Information, collected automatically by the Company: on each visit our website(s), the Company will automatically collect the following:</p>
  <p>Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; information about the visits, including the full Uniform Resource Locators (URL), clickstream to, through and from our website(s) (including date and time), products viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service number.</p>
  <p>Information may be collected by cookies and other tracking. Cookies are small data files that are stored on your device when visiting a website, which enable the Company to collect information about your device identifiers, web browsers used to access the Services, pages or features viewed, time spent on pages, mobile app performance and links clicked. Web beacons (or pixel tags) are electronic images that may be used to help deliver cookies, count website visits, understand usage and determine the effectiveness of email marketing campaigns.</p>
  <p>Participants are advised that if they wish to deny the use and saving of cookies from this Website on their computer’s hard drive they should take necessary steps within their web browsers security settings to block all cookies from our website(s). Also, Elrond offers to its Users the choice to disable the collection of anonymized data.</p>

  <h2>Survey Information</h2>
  <p>We may from time to time invite you to participate in surveys about your experience using our website(s). If you agree to participate in a survey, we collect the information included in your answers to the survey.</p>
  <p>Information, which is received from third-parties: this includes, but is not limited to Elrond’s partners, sub-contractors in technical, payment services, advertising networks, search information providers, credit reference agencies, or other third parties who may provide information, for the purpose of fulfilling the Services or to comply with legal requirements.</p>
  <p>The Company shall endeavour that these third parties are to provide the same level of data protection and that they shall only carry out their contractual obligations towards Elrond or upon the instructions of the Company and not for any other purposes.</p>
  <p>In case of using a location-enabled device: the Company may collect location data or use various means to determine the location.</p>

  <h2>Use of Information</h2>
  <p>The Company may use your Personal Data to supply you with the Company Services required, to bill you and to contact you when required. The Company may also analyse the Personal Data provided to help in administering, supporting and improving the business, as per the following ways:</p>
  <ul>
    <li>to provide, maintain, deliver and improve the Company Services and obtaining your views of the Company Services;</li>
    <li>to send periodic emails with products and services;</li>
    <li>to follow up with the User after correspondence (live chat, email or phone inquiries);</li>
    <li>for marketing and retargeting purposes;</li>
    <li>to carry out any other purpose for which the information was collected.</li>
  </ul>
  <p>If you want to stop receiving information as per the above, you can opt out at any time by contacting us at the email address indicated in this Privacy Policy. At all times, access to the User’s Personal Data by the Company’s own staff and third-party intermediaries, will be restricted to the bare minimum required for the Company to perform our business and the provision of the Company Services.</p>

  <h2>Consent</h2>
  <p>Your Personal Data may be processed only if you have unambiguously given your consent or, the processing is necessary for the performance of the Company Services you request or, for compliance with legal obligations as in the case of Anti Money Laundering and Terrorist Financing Regulations (AML/TF) or, pursuant the public interest or, requested in the exercise of official authority vested in Elrond or a third party with whom the Personal Data is disclosed except where such interest is overridden by the interest to protect the fundamental rights and freedoms that arise from the right of privacy.</p>
  <p>As an individual you may exercise your right to access your Personal Data held about you by Elrond by contacting our Data Protection Officer at the address below.</p>

  <h2>Rectification, deletion</h2>
  <p>The Company shall at your request immediately rectify, block or erase your Personal Data that has not been processed according to the GDPR and Data Protection Act or processed unlawfully and where applicable proceed with notifying any third party about the measures undertaken. Although all reasonable efforts will be made to keep your information updated, you are kindly requested to inform us of any change referring to the Personal Data held by the Company. In any case if you consider that certain information about you is inaccurate, you may request rectification of such data.</p>
  <p>Provided that no such notification shall be implemented if it is shown to be impossible or it will entail a disproportionate effort on the part of the Company.</p>

  <h2>Data Portability</h2>
  <p>You have the right to receive, upon request, a copy of the Personal Data that you have provided to the Company in a structured, commonly used and machine-readable format and to transmit such data to another controller, for free. The Company shall endeavour to ensure that such requests are processed within one month for free, subject that this request is not excessive and does not affect the rights of other individuals’ Personal Data.</p>

  <h2>Right to be forgotten</h2>
  <p>Upon request, you have the right to have your Personal Data erased by the Company. The Company acting reasonably will take all necessary actions (including technical measures) to inform third-party data Processors to comply with the request unless your Personal Data needs to be retained to comply with legal obligations or court orders.</p>

  <h2>Data Subject’s Support</h2>
  <p>The Company may provide its Users with user support through an online chat with an agent. Username and email address might be necessary to sign up for online chat. The data collected in this manner shall be processed exclusively for the purpose of providing user support.</p>

  <h2>International or Cross-border Transfer of Your Data and Your Express Agreement</h2>
  <p>The transfer of the User’s data may be necessary for using the Company Services, to provide updates and to respond to the User’s inquiries. As the internet operates in a global environment, the use of the internet to collect and process personal data necessarily involves the transmission of data on an international or cross-border basis. By using the Company Services or by communicating with us, you acknowledge and expressly consent to Elrond’s processing and disclosure of your Personal Data in this way. By communicating with us and using the Company Services, you provide your express consent to Elrond’s disclosure of your personal data to third-party intermediaries (if applicable) for the purposes described in this Privacy Policy.</p>

  <h2>Sharing of Information</h2>
  <p>Elrond may share the Users’ data with others as follows:</p>
  <ul>
    <li>With partners providing the Company Services;</li>
    <li>When using interactive areas of the Website, like our blog or other online forums, certain information you choose to share may be displayed publicly, such as your username, actions you take and any content you post;</li>
    <li>To Courts and Government authorities or bodies if requested to do so under a court order or legal process, or to establish or exercise the Company’s legal rights or defend against legal claims;</li>
    <li>If the Company believes that the User’s actions are inconsistent with the Terms or policies, or to protect the rights, property and safety of Elrond or others;</li>
    <li>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;</li>
    <li>Between and among the Company; or With your consent or at your direction.</li>
    <li>At any point in time you shall retain all rights to your data.</li>
  </ul>

  <h2>Security</h2>
  <p>The Company protects information collected using physical, technical, and administrative security measures to reduce the risks of disclosure, unlawful processing, accidental loss, destruction, damage and unauthorized access. Should a security breach occur, the Company will use reasonable endeavours to try to fix it.</p>

  <h2>Social Sharing and Links to Third Party Websites</h2>
  <p>When sharing the Company Services or other Elrond’s information on social websites, this will enable the sharing of information with your contacts or the public, depending on the settings you establish with the entity that provides the social sharing feature. Service links to third party websites may be made available to you, and that are not owned or controlled by the Company.</p>
  <p>Please be aware that the Company is not responsible for the privacy practices of such websites. For more information about the purpose and scope of data collection and processing in connection with social sharing features and links to third party websites, the Company encourages you to visit the privacy policies of the entities that provide these features.</p>
  <p>Furthermore, you are advised to conduct yourself appropriately when engaging with Elrond on social media or when sharing the website(s), Company Services or other Elrond’s information.</p>

  <h2>Data Retention</h2>
  <p>The Company will retain your Personal Data and that of beneficiaries of the Company Services for a reasonable period or as long as the law requires. The Company will retain your data as long as needed for the provision of the Company Services.</p>

  <h2>Disposal of Personal Data</h2>
  <p>When the Company receives requests to dispose of Personal Data records by Data Subjects, the Company shall ensure that these requests are handled within a reasonable time frame. The Company shall keep a record including a log of these requests.</p>
  <p>The Company shall also strive in obtaining adequate disposal mechanisms to ensure no Personal Data is leaked outside of Elrond.</p>

  <h2>Response to Personal Data Breaches</h2>
  <p>When the Company learns of a suspected or actual Personal Data breach, the Company shall perform an internal investigation and take appropriate remedial measures in a timely manner. Where there is any risk to the rights and freedoms of Data Subjects, the Company will notify the relevant Supervisory Authorities without undue delay and, when possible, within 72 hours from when it learns of such breach. Data Subjects will be informed when the Personal Data breach is of a “high” level.</p>

  <h2>Governing Law</h2>
  <p>By using the Services, or otherwise providing information to the Company, you consent to the processing and transfer of information in accordance with Romanian law.</p>

  <h2>Final Provisions</h2>
  <p>You may request the Company to access your information and Personal Data at any time. The Company shall be responsible for and be able to demonstrate compliance with the principles outlined in this Privacy Policy. The Company may at its own discretion assign or transfer this Privacy Policy and your related information to any person or entity that acquires or is merged with us without restriction.</p>
  <p>Any claims, questions, comments and requests regarding this Privacy Policy should be addressed to the Company by contacting our Data Protection Officer at the address below.</p>

  <h2>Changes to the Privacy Policy</h2>
  <p>The Company may update this Privacy Policy from time to time at its own discretion and in response to changing legal, technical and/or business development. The revised Privacy Policy will take effect immediately upon publication by the Company and we will replace this page with an updated version. When we update our Privacy Policy, we shall take appropriate action and inform you accordingly by a Privacy Notice consistent with the significant changes we would have made.</p>
  <p>However, you acknowledge that it is your sole responsibility to check the “Privacy Policy” page from time to any time so as to be aware of any changes which may have occurred from time to time.</p>

  <h2>Validity</h2>
  <p>This document was updated on 30 June 2022 and is effective from that date.</p>

  <h2>Your rights</h2>
  <p>You have the right to make a request to access or delete any of your personal data that we might possess. You can make a request by contacting our Data Protection Officer at the address below. You may be required to provide additional information to authenticate your request.</p>

  <h2>Contacts</h2>
  <p>We are very open about privacy and recognize your trust as a great value. If you have any questions about this statement or any privacy issues in our applications or services, feel free to contact our Data Protection Officer by post or email:</p>

  <p>Elrond Network SRL</p>
  <p>Address: 5 Nicolaus Olahus street, Corp B, 9th floor,</p>
  <p>Sibiu, Romania</p>
  <p>
    e-mail:
    {' '}
    <a href={'mailto:contact@elrond.com'}>contact@elrond.com</a>
  </p>

</div>
