import { from, map } from 'rxjs'
import { accounts } from 'repositories/accounts'
import { mergeMap } from 'rxjs/operators'
import { isHeroTag } from 'viewer/hooks/use-viewer-fetching'
import { get } from 'lodash'

const failureCodes = [403]

const formatResponse = data => ({ private: failureCodes.includes(get(data, 'statusCode', '')) })

const fetchHerotag = payload => accounts.byIdMaiarUsername(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(map(formatResponse))

const fetchAddress = payload => accounts.byIdMaiarAddress(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(map(formatResponse))

export const repository = ({ payload }) => {
  if (isHeroTag(payload) && payload.charAt(0) === '@') return fetchHerotag(payload.substring(1))

  return fetchAddress(payload)
}
