import { useCallback } from 'react'
import { useAuctionOwnerAddress } from 'startup/hooks/use-hydrate-location/use-auction-owner-address'

const getModalRoute = () => window.location.pathname.split('/modal')[1] ? window.location.pathname : ''

const getForegroundRoute = () => window.location.pathname.split('/modal')[0]

const getBackgroundRoute = address => `/${address}`

const makeState = ownerAddress => ({
  layerLink: {
    modal: getModalRoute(),
    foreground: getForegroundRoute(),
    background: getBackgroundRoute(ownerAddress),
  },
})

export const useMakeAuctionState = () => {
  const ownerAddress = useAuctionOwnerAddress()

  return useCallback(() => ownerAddress && makeState(ownerAddress), [ownerAddress])
}
