import { sanitizeStrict } from 'common/hooks/sanitize-strict'
import { debounce } from 'lodash'
import { useMemo } from 'react'

let instance

export const useDebouncedSearch = runSearch => {
  const ceva = useMemo(() => debounce(searchTerm => {
    const safeSearchTerm = sanitizeStrict(searchTerm)

    safeSearchTerm?.length >= 3 && runSearch(safeSearchTerm)
  }, 300), [runSearch])

  if (!instance) instance = ceva

  return instance
}
