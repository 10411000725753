import { useDispatch } from 'react-redux'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useParams } from 'react-router-dom'
import { useContext } from 'nft-page/mutate-like/context'
import { useCallback } from 'react'
import { add } from 'nft-page/likes/slices'

export const useAddLike = () => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()
  const { identifier } = useParams()
  const { setIsLiked } = useContext()

  return useCallback(() => {
    setIsLiked(true)
    dispatch(add.actions.attempt({ input: { identifier }, address }))
  }, [setIsLiked, dispatch, identifier, address])
}
