import match from '@nerds.sh/js-pattern'
import { highlightText } from 'common/highlight-text'
import { Username } from 'components/username/index'
import { VerifiedBadge } from 'components/verified-badge/index'

const usernameProps = ({ item }) => (
  item.name ? { herotag: `@${item.name.split('.')[0]}` } : { address: item.identifier }
)

// eslint-disable-next-line react/no-danger
export const makeHtml = text => <span dangerouslySetInnerHTML={{ __html: text }} />

const getTitle = (item, searchPhrase) => match(item)
  .like({ kind: 'account' }, () => <Username {...usernameProps(item)} highlightedText={searchPhrase} />)
  .like({ kind: 'tag' }, () => makeHtml(highlightText(searchPhrase, `#${item.item.identifier}`)))
  .like({ kind: 'collection' }, () => makeHtml(highlightText(searchPhrase, item.item.name)))
  .like({ kind: 'asset' }, () => makeHtml(highlightText(searchPhrase, item.item.name)))
  .otherwise(() => '')

export const Title = ({ item, searchPhrase }) => <div className={'search-results-item-title'}>
  {getTitle(item, searchPhrase)}
  {item.item.verified && <VerifiedBadge />}
</div>

