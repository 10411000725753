import { useTopNavigationContext } from 'components/top-navigation/context'
import { useCallback } from 'react'

export const useHandleBlur = () => {
  const { setSearchResultsOpen, setSelectedResult } = useTopNavigationContext()

  return useCallback((e, props) => {
    props.handleBlur(e)
    setSelectedResult(-1)
    setTimeout(() => setSearchResultsOpen(false), 150)
  }, [setSearchResultsOpen, setSelectedResult])
}

