import { useSelector } from 'react-redux'
import { get } from 'lodash'

const path = 'owners.owners.data.owners.edges'

export const useLastOwner = () => useSelector(state => {
  const owners = get(state, path, [])

  return owners.slice(-1)[0]
})
