import { CollectionRedirect } from 'search/redirects/collection-redirect'
import { AccountRedirect } from 'search/redirects/account-redirect'
import { HerotagRedirect } from 'search/redirects/herotag-redirect'
import { AssetRedirect } from 'search/redirects/asset-redirect'
import { ExploreRedirect } from 'search/redirects/explore-redirect'

export const Redirects = props => <>
  <CollectionRedirect />
  <AccountRedirect />
  <HerotagRedirect />
  <AssetRedirect {...props} />
  <ExploreRedirect />
</>
