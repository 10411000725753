import { CreatedDate } from 'nft-page/details/other-details/created-date'
import { Supply } from 'nft-page/details/other-details/supply'
import { Creator } from 'nft-page/details/other-details/creator'
import { FileType } from 'nft-page/details/other-details/file-type'
import { Tags } from 'nft-page/details/other-details/tags'
import { Type } from 'nft-page/details/other-details/type'
import { Uris } from 'nft-page/details/other-details/uris'
import { Owners } from 'nft-page/details/other-details/owners'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { TabContent } from 'components/tabs/tab-content'
import { Properties } from 'nft-page/details/other-details/properties'
import { Royalties } from 'nft-page/details/other-details/royalties'
import { Identifier } from 'nft-page/details/identifier'

export const OtherDetails = () => {
  const type = useNftSelector('nftPage.data.assets.edges[0].node.type')

  return <TabContent id={'nft-page-details-tab-content'} active>
    <div className={'p-lg-1 p-md-3'}>
      <Properties />
      {/* <RarityScore /> */}
      {/* <RarityRank /> */}
      <Identifier />
      <CreatedDate />
      {type !== 'NonFungibleESDT' ? <Supply /> : ''}
      <Owners />
      <Creator />
      <FileType />
      <Tags />
      <Type />
      <Royalties />
      <Uris />
    </div>
  </TabContent>
}
