import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'search/slices/dynamic'
import { useDynamicSearchReset } from 'search/hooks/use-dynamic-search-reset'

export const useRunDynamicSearch = () => {
  const dispatch = useDispatch()
  const resetSearch = useDynamicSearchReset()

  return useCallback(searchTerm => {
    resetSearch()

    searchTerm && dispatch(actions.attempt({ filters: { searchTerm } }))
  }, [dispatch, resetSearch])
}
