import { useParams } from 'react-router-dom'

const selectedAuctionPath = 'nft.selectedAuction.data.auctions.edges[0].node'

const lowestAuctionPath = 'nft.nftPage.data.assets.edges[0].node.lowestAuction'

export const useAuctionPath = () => {
  const { auctionId } = useParams()

  return auctionId ? selectedAuctionPath : lowestAuctionPath
}
