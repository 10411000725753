import { useIsAuctionEnded } from 'nft-page/hooks/use-is-auction-ended'
import { Content } from 'nft-page/auction/editions/content'
import { isAuctionTypeNft } from 'common/is-auction-type-nft'

const isSft = auction => auction && !isAuctionTypeNft(auction.type)

export const Editions = ({ auction }) => {
  const isAuctionEnded = useIsAuctionEnded()

  return isSft(auction) && !isAuctionEnded ? <Content auction={auction} /> : ''
}
