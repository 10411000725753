import { useTopNavigationContext } from 'components/top-navigation/context'
import { useCallback } from 'react'
import { } from 'search/hooks/use-search-results'
import match from '@nerds.sh/js-pattern'
import { useDynamicSearchResults } from 'search/hooks/use-dynamic-search-results'

const useHandleKeyUp = () => {
  const { setSelectedResult } = useTopNavigationContext()
  const results = useDynamicSearchResults()

  return useCallback(e => {
    setSelectedResult(old => old - 1 < 0 ? results.length - 1 : old - 1)
  }, [setSelectedResult, results])
}

const useHandleKeyDown = () => {
  const { setSelectedResult } = useTopNavigationContext()
  const results = useDynamicSearchResults()

  return useCallback(e => {
    setSelectedResult(old => old + 1 > results.length - 1 ? 0 : old + 1)
  }, [setSelectedResult, results])
}

export const useHandleKeys = () => {
  const handleKeyUp = useHandleKeyUp()
  const handleKeyDown = useHandleKeyDown()

  return useCallback(e => match(e)
    .like({ key: 'ArrowDown' }, handleKeyDown)
    .like({ key: 'ArrowUp' }, handleKeyUp)
    .otherwise(() => { }), [handleKeyDown, handleKeyUp])
}
