import { useDispatch } from 'react-redux'
import { useEndCursor } from 'subdomain/modals/account/my-auctions/hooks/use-end-cursor'
import { useCallback } from 'react'
import { actions } from 'subdomain/modals/account/my-auctions/slices/get'
import { payload } from 'subdomain/modals/account/my-auctions/payload'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const dispatch = useDispatch()
  const cursor = useCursor(customCursor)
  const { address } = useGetAccountInfo()
  const marketplaceKey = useCmsMarketplaceKey()

  return useCallback(() => {
    const shouldCall = address && marketplaceKey

    shouldCall && dispatch(actions.attempt(payload({ address, cursor, marketplaceKey })))
  }, [address, marketplaceKey, dispatch, cursor])
}
