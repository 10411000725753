import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { follows } from 'account/profile/follow/slices/follows'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useIsPrinceCharming } from '../../../../auth/mega-authorized'

export const useFollows = () => {
  const dispatch = useDispatch()
  const viewerAddress = useViewerAddress()
  const { address } = useGetAccountInfo()
  const wereAddressesFetched = address && viewerAddress
  const isPrinceCharming = useIsPrinceCharming()
  useEffect(() => {
    isPrinceCharming && wereAddressesFetched && dispatch(follows.actions.attempt({ address: viewerAddress }))
  }, [dispatch, viewerAddress, wereAddressesFetched, isPrinceCharming])
}
