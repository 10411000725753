import { useHandleKeys } from 'components/top-navigation/components/search-form/hooks/use-handle-keys'
import { useHandleBlur } from 'components/top-navigation/components/search-form/hooks/use-handle-blur'
import { useHandleFocus } from 'components/top-navigation/components/search-form/hooks/use-handle-focus'
import { useHandleChange } from 'components/top-navigation/components/search-form/hooks/use-handle-change'

export const Input = ({ formikProps }) => {
  const handleChange = useHandleChange()
  const handleFocus = useHandleFocus()
  const handleBlur = useHandleBlur()
  const handleKeys = useHandleKeys()

  return <input className={'form-control search-input'}
                onChange={e => handleChange(e, formikProps)}
                onBlur={e => handleBlur(e, formikProps)}
                onFocus={e => handleFocus()}
                onKeyDown={handleKeys}
                value={formikProps.values.search}
                placeholder={'Search items, collections, creators...'} name={'search'} autoComplete={'off'} />
}
