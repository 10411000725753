import { useCallback } from 'react'

import { useRunDynamicSearch } from 'search/hooks/use-run-dynamic-search'
import { useDynamicSearchReset } from 'search/hooks/use-dynamic-search-reset'
import { useDebouncedSearch } from 'components/top-navigation/components/search-form/hooks/use-debounced-search'

export const useOnChange = () => {
  const runSearch = useRunDynamicSearch()
  const resetSearch = useDynamicSearchReset()
  const debouncedSearch = useDebouncedSearch(runSearch)

  return useCallback(searchTerm => {
    resetSearch()
    debouncedSearch(searchTerm)
  }, [debouncedSearch, resetSearch])
}
