import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'current-profile/slices/current-profile'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useIsPrinceCharming } from 'auth/mega-authorized'

export const useCurrentProfile = () => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()
  const isPrinceCharming = useIsPrinceCharming()

  useEffect(() => {
    isPrinceCharming && address && dispatch(actions.attempt(address))
  }, [dispatch, address, isPrinceCharming])
}
