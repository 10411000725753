import { useContent } from 'auction-flows/hooks/use-content'
import { statuses } from 'nft-page/details/other-listings/actions/statuses'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

export const Content = ({ asset, auction }) => {
  const { address } = useGetAccountInfo()
  const marketplaceKey = useCmsMarketplaceKey()
  const Content = useContent({ auction, asset, address, marketplaceKey, statuses: statuses() })

  return <Content asset={asset} auction={auction} address={address} />
}

