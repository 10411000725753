import { combineLatest, from, of } from 'rxjs'
import { mergeMap, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { actions } from 'modals/account/cover/slices/update-cover'
import { actions as profileActions } from 'account/profile/slices/profile'
import { identity } from 'repositories/identity'
import { getAddress } from '@elrondnetwork/dapp-core/utils'

const refreshProfile = () => from(getAddress()).pipe(map(address => profileActions.attempt(address)))

export const updateCover = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => identity().self.setCoverPhoto(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const successActions = payload => [
  refreshProfile(),
  of(actions.success(payload)),
]

const success = payload => combineLatest(successActions(payload))
  .pipe(mergeMap(actions => actions))

const failure = payload => [
  actions.failure(payload),
]
