import { Details } from 'nft-page/auction/details'
import { Link } from 'nft-page/auction/actions/view-on-other-market/link'
import { get } from 'lodash'

export const ViewOnOtherMarket = ({ auction }) => {
  const marketplace = get(auction, 'marketplace', {})

  return <div className={'nft-page-auction-inner'}>
    <Details auction={auction} />
    <Link marketplace={marketplace} />
  </div>
}

ViewOnOtherMarket.displayName = 'ViewOnOtherMarket'
