import { from } from 'rxjs'
import { ofType } from 'redux-observable'
import { identity } from 'repositories/identity'
import { mergeMap, catchError } from 'rxjs/operators'
import { actions } from 'account/profile/slices/profile'
import { loading } from 'loading/slices'
import { Loading } from 'loading/index'

export const profile = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => identity().users(payload)
  .pipe(mergeMap(result => from(result.json())))
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => from([
  actions.success(payload),
  loading.actions.hide({ id: Loading.ID.account.profile, variant: Loading.VARIANT.partial }),
])

const failure = errors => from([
  actions.failure(errors),
  loading.actions.hide({ id: Loading.ID.account.profile, variant: Loading.VARIANT.partial }),
])
