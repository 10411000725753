import { ofType } from 'redux-observable'
import { actions } from '../slices'
import { catchError, mergeMap } from 'rxjs/operators'
import { preferences as repository } from '../../repositories/preferences'
import { from, of } from 'rxjs'

const loaded = payload => of(actions.loaded(payload))

const failure = payload => from([
  actions.failure(payload),
])

export const execute = () => repository.get()
  .pipe(mergeMap(response => from(response.json())))
  .pipe(mergeMap(loaded))
  .pipe(catchError(failure))

export const load = actions$ => actions$
  .pipe(ofType(actions.load))
  .pipe(mergeMap(execute))

