import { get } from 'lodash'
import { Bundle } from 'nft-page/auction/bundle'
import { isAuctionTypeNft } from 'common/is-auction-type-nft'

export const SftBundle = ({ auction }) => {
  const auctionType = get(auction, 'type', '')
  const isAuctionTypeSft = !isAuctionTypeNft(auctionType)

  return isAuctionTypeSft ? <Bundle auction={auction} /> : ''
}
