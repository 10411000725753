import { Logo } from 'components/top-navigation/components/logo'
import { Connect } from 'components/top-navigation/components/connect'
import { SearchToggle } from 'components/top-navigation/components/search-toogle'
import { SearchForm } from 'components/top-navigation/components/search-form'
import { Menu } from 'components/top-navigation/components/menu/menu'
import { useContext } from 'react'
import { TopNavigationContext } from 'components/top-navigation/context'

const className = 'navbar navbar-expand-md justify-content-start navbar-light navigation'

export const Navigation = () => {
  const { searchOpen } = useContext(TopNavigationContext)

  return !searchOpen && <nav className={className}>
    <div className={'container'}>
      <Logo />
      <div className={'d-flex mx-3 d-none d-lg-block flex-grow-1'}>
        <SearchForm />
      </div>
      <div className={'d-flex justify-content-end flex-grow-1 flex-lg-grow-0'}>
        <SearchToggle />
      </div>
      <Menu className={'d-none d-lg-block'} />
      <div className={'d-flex justify-content-end align-items-center'}>
        <Connect />
      </div>
    </div>
  </nav>
}
