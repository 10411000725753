import { mergeMap, from, filter, map } from 'rxjs'
import { settings } from 'settings/fetching'
import { rest } from 'network'

const makeUrl = cursor => {
  const query = new URLSearchParams()
  cursor && query.append('cursor', cursor)

  return `${settings().tools}/feed-api/v1/feed?${query.toString()}`
}

const getImplementedEvents = () => settings().implementedFeedEvents

export const raw = ({ cursor }) => rest().get(makeUrl(cursor), {}, true)
  .pipe(mergeMap(res => from(res.json())))
  .pipe(filter(({ cursor: nextCursor }) => nextCursor))
  .pipe(map(({ cursor, items }) => ({ cursor, items: items.filter(({ event }) => getImplementedEvents().includes(event)) })))
