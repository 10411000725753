import { useMakeNftState } from 'startup/hooks/use-hydrate-location/use-make-nft-state'
import { useMakeDefaultState } from 'startup/hooks/use-hydrate-location/use-make-default-state'
import match from '@nerds.sh/js-pattern'
import { useCallback } from 'react'
import { useShouldUseNftState } from 'startup/hooks/use-hydrate-location/use-should-use-nft-state'
import { useShouldUseAuctionState } from 'startup/hooks/use-hydrate-location/use-should-use-auction-state'
import { useMakeAuctionState } from 'startup/hooks/use-hydrate-location/use-make-auction-state'
import { useShouldUseTransactionState } from 'startup/hooks/use-hydrate-location/use-should-use-transaction-state'
import { useMakeTransactionState } from 'startup/hooks/use-hydrate-location/use-make-transaction-state'
import { useShouldUseAccountState } from 'startup/hooks/use-hydrate-location/use-should-use-account-state'
import { useMakeAccountState } from 'startup/hooks/use-hydrate-location/use-make-account-state'
import { useShouldUseCollectionState } from 'startup/hooks/use-hydrate-location/use-should-use-collection-state'
import { useMakeCollectionState } from 'startup/hooks/use-hydrate-location/use-make-collection-state'

export const useMakeTargetState = () => {
  const shouldUseNftState = useShouldUseNftState()
  const makeNftState = useMakeNftState()
  const makeDefaultState = useMakeDefaultState()
  const shouldUseAuctionState = useShouldUseAuctionState()
  const makeAuctionState = useMakeAuctionState()
  const shouldUseCollectionState = useShouldUseCollectionState()
  const makeCollectionState = useMakeCollectionState()
  const shouldUseAccountState = useShouldUseAccountState()
  const makeAccountState = useMakeAccountState()
  const shouldUseTransactionState = useShouldUseTransactionState()
  const makeTransactionState = useMakeTransactionState()

  return useCallback(() => match({})
    .with(() => shouldUseNftState, makeNftState)
    .with(() => shouldUseAuctionState, makeAuctionState)
    .with(() => shouldUseCollectionState, makeCollectionState)
    .with(() => shouldUseAccountState, makeAccountState)
    .with(() => shouldUseTransactionState, makeTransactionState)
    .otherwise(makeDefaultState), [makeAuctionState, makeDefaultState, makeNftState, makeCollectionState, makeAccountState, makeTransactionState, shouldUseAuctionState, shouldUseNftState, shouldUseCollectionState, shouldUseAccountState, shouldUseTransactionState])
}
