import { Wrapper } from 'admin/wrapper'
import { Actions } from 'admin/actions'
import 'admin/style.scss'

export const AdminSidebar = () => <Wrapper>
  <div className={'admin-sidebar-container'}>
    <div className={'admin-sidebar-content px-1'}>
      <Actions />
    </div>
  </div>
</Wrapper>

export { reducer } from 'admin/reducer'
export { epic } from 'admin/epic'
