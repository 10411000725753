import { layers } from 'startup/layers'
import { Sidebar } from 'sidebar'
import { Behaviour } from 'startup/behaviour'
import { LayeredRouter } from 'components/layered-router'
import { TopNavigation } from 'components/top-navigation'
import './layout.scss'
import { Notifications } from 'notifications'
import { SignTransactionsModals } from '@elrondnetwork/dapp-core/UI'
import { ExtensionEdgeCaseFix } from 'layouts/extension-edge-case-fix'

export const OneColumnLayout = () => <div className={'layout d-flex flex-column min-vh-100'}>
  <LayeredRouter layers={layers}>
    <Behaviour />
    <ExtensionEdgeCaseFix />
    <TopNavigation />
    <Sidebar />
    <Notifications />
    <SignTransactionsModals verifyReceiverScam={false} />
  </LayeredRouter>
</div>

