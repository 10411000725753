import 'components/top-navigation/index.scss'
import { TopNavigationContext, useDefaultContext } from 'components/top-navigation/context'
import { Navigation } from 'components/top-navigation/components/navigation'
import { useShouldShow } from 'components/top-navigation/hooks/use-should-show'
import { SearchOverlay } from 'components/top-navigation/components/search-overlay'
import { useCloseMenu } from 'components/top-navigation/hooks/use-close-menu'
import { MobileMenu } from 'components/top-navigation/components/mobile-menu/index'
import { Redirects } from 'search/redirects'
import { useIsNftView } from 'startup/hooks/use-is-nft-view'

export const TopNavigation = () => {
  useCloseMenu()
  const blurredClass = useIsNftView() ? 'blurred' : ''

  return <>
    <div className={`top-navigation-wrapper ${blurredClass}`} hidden={!useShouldShow()}>
      <TopNavigationContext.Provider value={useDefaultContext()}>
        <Redirects />
        <SearchOverlay />
        <Navigation />
      </TopNavigationContext.Provider>
    </div>
    <MobileMenu />
  </>
}
