import { Title } from '../title'
import { Links } from '../links'
import './section.scss'

const className = 'footer-section col-6 col-md-3 mb-3'

export const Section = ({ section }) => <div className={className}>
  <Title title={section.title} />
  <Links links={section.links} />
</div>
