import { AuthorizedLink } from './authorized-link'
import { Link as RouterLink } from 'react-router-dom'

export const LocalLink = ({ link }) => {
  if (link.authorized) {
    return <AuthorizedLink link={link} />
  } else {
    return <RouterLink className={'footer-section-link'} to={link.href}>
      {link.title}
    </RouterLink>
  }
}
